<template>
  <section class="user">
    <profile v-if="user_action_type === 'profile'"/>
    <support v-else-if="user_action_type === 'support'"/>
    <user_management v-else-if="user_action_type === 'user_management' && permissions.user.sections.permissions.module"/>
    <settings v-else-if="user_action_type === 'settings' && permissions.user.sections.settings.module"/>
    <store_settings v-else-if="user_action_type === 'store_settings' && permissions.admin"/>
    <p v-else class="md-headline no-permissions">
      {{ $translate("no_permissions_message") }}
    </p>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { ADMIN_STORE, USER_STORE } from "../../constants/others_constants"
import profile from "../utils/user/profile"
import user_management from "../utils/user/user_management"
import settings from "../utils/user/settings"
import support from "../utils/user/support"
import store_settings from "../utils/user/store_settings"

export default {
  components: {
    profile,
    user_management,
    settings,
    support,
    store_settings,
  },
  computed: {
    ...mapState(ADMIN_STORE, ["feature_toggles"]),
    ...mapState(USER_STORE, ["permissions"]),
    user_action_type() {
      return this.$route.params.user_action_type
    }
  }
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_admin-constants" as *;

.user {
  padding: 0 $half-default-size;
}
</style>
